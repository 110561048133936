<template>
  <b-card>
    <single-session-event-form
      :event="event"
      :session="session"
      :submit="submitSingleSessionEvent"
    />
  </b-card>
</template>

<script>
import QuickEventApi from '@/common/compositions/QuickEvents/quickEventApi'
import SessionApi from '@/common/compositions/QuickEvents/sessionApi'
import SingleSessionEventForm from '@/common/components/QuickEvents/SingleSessionEventForm.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  components: { SingleSessionEventForm },
  setup() {
    const { event, progress, addEventRequest } = QuickEventApi()
    const {
      session, addSessionRequest,
    } = SessionApi()
    const { successfulOperationAlert } = handleAlerts()
    return {
      event, progress, addEventRequest, session, addSessionRequest, successfulOperationAlert,
    }
  },
  created() {
    this.event = {
      organizationId: this.$store.getters['mainEntity/getEntityId'],
      organizerUserId: this.$store.getters['auth/getUserData'].id,
      contactName: `${this.$store.getters['auth/getUserData'].profile?.first_name} ${this.$store.getters['auth/getUserData'].profile?.last_name}`,
      contactNumber: this.$store.getters['auth/getUserData'].profile?.phone,
      location: {},
      eventDates: {},
      activateBeforeHours: 0,
      frequency: 'once',
      brochure: [],
      image: [],
      visibility: 'private',
      singleSession: true,
      ...JSON.parse(this.$route.query.event),
    }

    this.$set(this.session, 'start_time', this.$moment(this.event.eventDates.startDate).format('YYYY-MM-DD HH:mm'))
    this.$set(this.session, 'end_time', this.$moment(this.event.eventDates.endDate).format('YYYY-MM-DD HH:mm'))
    this.$set(this.session, 'target_gender', this.event.target_audience)
    this.$set(this.session, 'venue', this.event.venue_name)
  },
  methods: {
    submitSingleSessionEvent() {
      return this.addEvent().then(res => {
        const event = res.data.data[0] || {}
        this.setDuplicateDataInSession(event)
        return this.addSessionRequest(event.id)
      })
    },
    addEvent() {
      return this.addEventRequest().then(res => {
        const event = res.data.data[0]

        this.$set(this.event, 'id', event.id)

        this.successfulOperationAlert('Event is added successfully')
        this.$router.back()
        return res
      })
    },
    setDuplicateDataInSession(event) {
      this.$set(this.session, 'topic', event.name)
      this.$set(this.session, 'start_time', event.startTime)
      this.$set(this.session, 'end_time', event.endTime)
      this.$set(this.session, 'general_notes', event.generalNotes)
    },
  },
}
</script>

<style>

</style>
